import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

// import marlonDavila from "../../images/mural-cards/pics/7ovechild-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const MarlonDavilaModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      marlonDavila: file(
        relativePath: { eq: "mural-cards/pics/7ovechild-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={marlonDavila} alt="@7OVECHILD" /> */}
                <Img
                  fluid={imageData.marlonDavila.childImageSharp.fluid}
                  alt="@7OVECHILD"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@7OVECHILD</h4>
            <p>Marlon Davila</p>
            <p>
              He/Him <span className="ml-4">Newark, New Jersey</span>
            </p>
            <p>
              The artist also known as Marlon Davila is a 1st Generation
              Guatemalan born in Princeton, NJ. 7ove Child’s work is Influenced
              by fashion, the bright vibrant colors of his Mayan ancestry as
              well as the works of Frida Kahlo, Dali and Picasso. Infused in his
              art is his expression of present-day occurrences, Mayan ancestry
              and fashion.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="https://www.7ovechild.com/"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Visit Shop
              </a>
              <a
                href="https://www.instagram.com/7ovechild/"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>

        <h4 className="text-uppercase mt-4 text-danger">Artist Statement</h4>
        <p>
          The inspiration behind the Skittles Mural art project is the history
          of the LGBTQ Pride movement and how they paved the way for us all
          today. The vibrant colors, the embracing of everyone’s differences,
          cultures and individuality was the driving force for me with this
          project.
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default MarlonDavilaModal
