import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

//import { Container, Figure } from "react-bootstrap"

// Images
// import logoSkittlesAgegate from "../images/agegate/logo-skittles-agegate.png"
// import skittlesChocoLeftTopMobile from "../images/agegate/skittles-choco-left-top-m.png"
// import skittlesChocoLeftTop from "../images/agegate/skittles-choco-left-top.png"
// import skittlesChocoRightBottom from "../images/agegate/skittles-choco-right-bottom.png"

import AgeCalculator from "../components/agegate-calculator"

const AgeGate = (props) => {
  const [isOldError, setIsOldError] = useState(false)
  const [isAgeInvalid, setIsAgeInvalid] = useState(false)
  const imageData = useStaticQuery(graphql`
    query {
      logoSkittlesAgegate: file(
        relativePath: { eq: "agegate/logo-skittles-agegate.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      skittlesChocoLeftTopMobile: file(
        relativePath: { eq: "agegate/skittles-choco-left-top-m.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      skittlesChocoLeftTop: file(
        relativePath: { eq: "agegate/skittles-choco-left-top.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      skittlesChocoRightBottom: file(
        relativePath: { eq: "agegate/skittles-choco-right-bottom.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      {/* Age Gate */}
      <div className="agegate-hldr">
        <div className="agegate text-center position-relative pb-5 pb-md-0">
          <div className="agegate-inner">
            <figure className="mb-4 logo">
              {/* <img
                className="img-fluid"
                src={logoSkittlesAgegate}
                alt="Skittles Logo"
              /> */}
              <Img
                fluid={imageData.logoSkittlesAgegate.childImageSharp.fluid}
                alt="Skittles Logo"
              />
            </figure>
            <h2 className="text-uppercase">WE'RE GLAD YOU'RE HERE</h2>
            <p className="lead">Please enter your birth date</p>

            <AgeCalculator
              //setAgeGateShow={props.setAgeGateShow}
              setAppShow={props.setAppShow}
              setIsOldError={setIsOldError}
              setIsAgeInvalid={setIsAgeInvalid}
            />
            {isOldError && (
              <p
                className="text-center mb-0 px-2 error"
                style={{ color: "red" }}
              >
                Sorry you do not meet our age requirements
              </p>
            )}
            {isAgeInvalid && (
              <p
                className="text-center mb-0 px-2 error"
                style={{ color: "red" }}
              >
                Please enter a valid date of birth.
              </p>
            )}
          </div>

          <figure className="mb-0 position-absolute skittles-choco-left-top d-md-none">
            {/* <img
              className="img-fluid "
              src={skittlesChocoLeftTopMobile}
              alt="Skittles Logo"
            /> */}
            <Img
              fluid={imageData.skittlesChocoLeftTopMobile.childImageSharp.fluid}
              alt="Skittles Choco Left Bottom Mobile"
            />
          </figure>

          <figure className="mb-0 position-absolute skittles-choco-left-top d-none d-md-block">
            {/* <img
              className="img-fluid "
              src={skittlesChocoLeftTop}
              alt="Skittles Logo"
            /> */}
            <Img
              fluid={imageData.skittlesChocoLeftTop.childImageSharp.fluid}
              alt="Skittles Choco Left Top"
            />
          </figure>

          <figure className="mb-0 position-absolute skittles-choco-right-bottom">
            {/* <img
              className="img-fluid"
              src={skittlesChocoRightBottom}
              alt="Skittles Logo"
            /> */}
            <Img
              fluid={imageData.skittlesChocoRightBottom.childImageSharp.fluid}
              alt="Skittles Choco Right Bottom"
            />
          </figure>
        </div>
      </div>
    </>
  )
}

export default AgeGate
