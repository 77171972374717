import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

// import saraMoroni from "../../images/mural-cards/pics/saramoronipizza-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const SaraMoroniModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      saraMoroni: file(
        relativePath: { eq: "mural-cards/pics/saramoronipizza-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={saraMoroni} alt="@SARAMORONIPIZZA" /> */}
                <Img
                  fluid={imageData.saraMoroni.childImageSharp.fluid}
                  alt="@SARAMORONIPIZZA"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@SARAMORONIPIZZA</h4>
            <p>Sara Moroni</p>
            <p>
              She/Her <span className="ml-4">Nashville, Tennessee</span>
            </p>
            <p>
              Sara is a queer southern artist with diverse and distinctive
              styles. She focuses on themes of queer and gender expression as
              well as humor and play.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="http://www.saramoronipizza.com"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Visit Shop
              </a>
              <a
                href="https://www.instagram.com/saramoronipizza/"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>

        <h4 className="text-uppercase mt-4 text-danger">Artist Statement</h4>
        <p>
          My inspiration was to showcase the diversity and intersectionality of
          queer voices in the South. I love being queer and I love being
          Southern. There is no reason for those statements to be
          contradictions. My mural uses positivity and play to highlight that we
          deserve to exist freely. As queer Southerners, we belong in this
          space. Hold hands. Kiss. Be seen in public spaces as queer. This is
          our home, too.
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default SaraMoroniModal
