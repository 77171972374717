import React from "react"

const MainNav = (props) => {
  return (
    <nav
      className={`main-nav position-absolute w-100 h-100 ${
        props.navShow ? "in" : ""
      }`}
    >
      <div className="container px-4 px-md-3">
        <div className="link-list">
          <div className="link">
            <a
              href="#"
              className="scrolltrigger"
              data-jump="welcome"
              data-section="#one"
            >
              Welcome <span className="link-bar"></span>
            </a>
          </div>
          <div className="link">
            <a
              href="#"
              className="scrolltrigger"
              data-jump="pride"
              data-section="#one"
            >
              Pride <span className="link-bar"></span>
            </a>
          </div>

          <div className="link">
            <a href="#" id="celebrateBtn">
              Celebrate <span className="link-bar"></span>
            </a>
          </div>
          <div className="link">
            <a href="#" id="resourcesBtn">
              Resources <span className="link-bar"></span>
            </a>
          </div>
          <div className="link">
            <a href="#" id="pridePacksBtn">
              Pride Packs <span className="link-bar"></span>
            </a>
          </div>
          <div className="link">
            <a href="#" id="commitmentsBtn">
              Commitments <span className="link-bar"></span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default MainNav
