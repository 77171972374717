import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

// import jakkPic from "../../images/influencer-cards/pics/jakk-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const JakkFynnModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      jakkPic: file(
        relativePath: { eq: "influencer-cards/pics/jakk-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={jakkPic} alt="@jakkfynn" /> */}
                <Img
                  fluid={imageData.jakkPic.childImageSharp.fluid}
                  alt="@jakkfynn"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@JAKKFYNN</h4>
            <p>Jakk Fynn</p>
            <p>He/Him/His</p>
            <p>
              Jakk Fynn is a trans masculine singer and songwriter based in Los
              Angeles. He started out in post-hardcore bands but has since moved
              into alt-pop. Jakk endured a tumultuous upbringing, but always
              found solace in music. Unfortunately, he never felt represented in
              mainstream media and became determined to help make space. To
              date, he’s been featured in Billboard, Vogue, Alternative Press,
              and more.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="https://open.spotify.com/artist/1LTc30hGuMzEa8gfqb8Qmu?si=tyyXt7aJSDWvZ1A9zfaOEw&nd=1"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Support
              </a>
              <a
                href="https://www.instagram.com/jakkfynn/"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default JakkFynnModal
